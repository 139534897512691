



















import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import objetivoModule from "@/store/modules/objetivo-module";
import { objetivo } from "@/shared/dtos/objetivo";
import enumeracionModule from "@/store/modules/enumeracion-module";
import { datatypes } from "@/components/DataTableGenerico/datatypes";

@Component({
  components: {},
})
export default class objetivosFormulario extends Mixins(HelperDinamicallyForm) {
  public item_pantalla: objetivo = new objetivo();

  public async OnCreated() {
    if (this.getparamId().HayParametro) {
      await objetivoModule.getobjetivo(this.getparamId().Parametro);
      this.item_pantalla = objetivoModule.objetivo;
    }
    await enumeracionModule.getenumeraciones_fromtipo(3);
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultString(
      this.item_pantalla.codigo,
      "Código",
      "codigo"
    )
      .isRequired()
      .Dimesiones(12, 6, 6);

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre"
    )
      .isRequired()
      .Dimesiones(12, 6, 6);

    /*this.AddFormItemDataDefaultString(
      this.item_pantalla.objetivo_llegar,
      "Objetivo",
      "objetivo_llegar"
    )
      .isRequired()
      .Dimesiones(12, 6, 6);*/

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.estrategias,
      "Metas estratégicas",
      "estrategias"
    )
      .CreateComboMultipleNames(enumeracionModule.enumeraciones, "nombre", "id")
      .Dimesiones(12, 6, 6);

    this.AddFormItemDataDefaultString(
      this.item_pantalla.descripcion,
      "Descripción",
      "descripcion",
      datatypes.maxlength
    )
      .CreateTextArea()
      .Dimesiones(12, 12, 12);
  }

  public Insertar(object: objetivo) {
    objetivoModule.guardarobjetivo(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: objetivo) {
    objetivoModule.modificarobjetivo(object).then(() => {
      this.AllSaveOk();
    });
  }
}
